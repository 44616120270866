import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Appraisal.css';

const AppraisalSection = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        message: ''
    });

    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const validateForm = () => {
        const newErrors = {};
        Object.keys(formData).forEach((field) => {
            if (!formData[field].trim()) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const sendEmail = (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        // Send email using EmailJS
        emailjs
            .send(
                'service_gmotd25', // Replace with your EmailJS service ID
                'template_54d1x3i', // Replace with your EmailJS template ID
                formData, // This sends the form data
                'no26ATx0bOIvBzFsy' // Replace with your EmailJS user ID
            )
            .then(
                (result) => {
                    console.log(result.text);
                    alert('Message sent successfully!');
                    setFormData({ name: '', email: '', phone: '', address: '', message: '' }); // Clear the form
                    setErrors({}); // Clear errors after successful submission
                },
                (error) => {
                    console.log(error.text);
                    alert('Failed to send the message, please try again.');
                }
            );
    };

    return (
        <section id="contact-984">
            <div className="cs-container">
                <picture className="cs-picture">
                    {/* Mobile Image */}
                    <source media="(max-width: 600px)" srcSet="./appraisal.jpg" />
                    {/* Tablet and above Image */}
                    <source media="(min-width: 601px)" srcSet="./appraisal.jpg" />
                    <img
                        loading="lazy"
                        decoding="async"
                        src="./appraisal.jpg"
                        alt="friends"
                        width="904"
                        height="754"
                    />
                </picture>
                <div className="cs-right">
                    <div className="cs-content">
                        <span className="cs-topper">Contact Us</span>
                        <h2 className="cs-title">Get a free appraisal</h2>
                    </div>
                    {/* Form */}
                    <form className="cs-form" id="cs-form-587-984" onSubmit={sendEmail}>
                        <label className="cs-label">
                            Name
                            <input
                                className="cs-input"
                                type="text"
                                id="name-587-984"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                placeholder="Name"
                                required
                            />
                            {errors.name && <span className="error">{errors.name}</span>}
                        </label>
                        <label className="cs-label">
                            Phone
                            <input
                                className="cs-input"
                                type="text"
                                id="phone-587-984"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                placeholder="Phone"
                                required
                            />
                            {errors.phone && <span className="error">{errors.phone}</span>}
                        </label>
                        <label className="cs-label">
                            Email
                            <input
                                className="cs-input"
                                type="email"
                                id="email-587-984"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                placeholder="Email"
                                required
                            />
                            {errors.email && <span className="error">{errors.email}</span>}
                        </label>
                        <label className="cs-label">
                            Address
                            <input
                                className="cs-input"
                                type="text"
                                id="address-587-984"
                                name="address"
                                value={formData.address}
                                onChange={handleInputChange}
                                placeholder="Address"
                                required
                            />
                            {errors.address && <span className="error">{errors.address}</span>}
                        </label>
                        <label className="cs-label cs-message">
                            Message
                            <textarea
                                className="cs-input cs-textarea"
                                name="message"
                                id="message-587-984"
                                value={formData.message}
                                onChange={handleInputChange}
                                placeholder="Write message..."
                                required
                            ></textarea>
                            {errors.message && <span className="error">{errors.message}</span>}
                        </label>
                        <button className="cs-button-solid cs-submit" type="submit">
                            Value my property!
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default AppraisalSection;
