import React from 'react';
import './MeetTheTeam.css';

const teamMembers = [
    {
        name: 'Anysos Gonczy',
        job: 'Managing Director of Inspire Real Estate',
        imageUrl: './anysos-gonczy.png',
    },
    {
        name: 'Craig Rawcliffe',
        job: 'Southern Regional Manager',
        imageUrl: './Craig-rawcliffe.png',
    },
    {
        name: 'Olivia Dalzell',
        job: 'Office Manager',
        imageUrl: './olivia-dalzell.png',
    },
];
const teamMembers1 = [
    {
        name: 'Samantha Kehoe',
        job: 'Property Manager',
        imageUrl: './samantha-kehoe.png',
    },
    {
        name: 'Amanda Peterson',
        job: 'Property Management Regional Manager',
        imageUrl: './amanda-peterson.png',
    },
    {
        name: 'Louise Payne',
        job: 'Real Estate Salesperson',
        imageUrl: './Louise-Payne.png',
    },
];

const MeetTheTeam = () => {
    return (
        <section id="meet-us-347">
            <div className="cs-container">
                <div className="cs-content">
                    <div className="cs-flex-group">
                        <span className="cs-topper">Meet Us</span>
                        <h2 className="cs-title">Our Team Members</h2>
                    </div>
                    {/* <a href="#" className="cs-button-solid">View All Members</a> */}
                </div>
                <ul className="cs-card-group">
                    {teamMembers.map((member, index) => (
                        <li className="cs-item" key={index}>
                            <picture className="cs-background">
                                <source media="(max-width: 767px)" srcSet={member.imageUrl} />
                                <source media="(min-width: 768px)" srcSet={member.imageUrl} />
                                <img
                                    aria-hidden="true"
                                    loading="lazy"
                                    decoding="async"
                                    src={member.imageUrl}
                                    alt={member.job}
                                    width="305"
                                    height="335"
                                />
                            </picture>
                            <div className="cs-info">
                                <span className="cs-name">{member.name}</span>
                                <span className="cs-job">{member.job}</span>
                            </div>
                        </li>
                    ))}
                </ul>
                <ul className="cs-card-group">
                    {teamMembers1.map((member, index) => (
                        <li className="cs-item" key={index}>
                            <picture className="cs-background">
                                <source media="(max-width: 767px)" srcSet={member.imageUrl} />
                                <source media="(min-width: 768px)" srcSet={member.imageUrl} />
                                <img
                                    aria-hidden="true"
                                    loading="lazy"
                                    decoding="async"
                                    src={member.imageUrl}
                                    alt={member.job}
                                    width="305"
                                    height="335"
                                />
                            </picture>
                            <div className="cs-info">
                                <span className="cs-name">{member.name}</span>
                                <span className="cs-job">{member.job}</span>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="cs-bubbles cs-bubbles-small" aria-hidden="true"></div>
                <div className="cs-bubbles cs-bubbles-big" aria-hidden="true">
                    <span className="cs-bubbles-big2"></span>
                </div>
            </div>
        </section>
    );
};

export default MeetTheTeam;
