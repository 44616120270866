import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './componants/Navbar';
import Footer from './componants/Footer';
import React, { Suspense } from 'react';

import Hero from './componants/Hero';
import MeetCarleen from './componants/MeetCarleen';
import Reviews from './componants/Reviews';
import CTA from './componants/CTA';
import MeetTheTeam from './componants/MeetTheTeam';
import Appraisal from './componants/Appraisal';
import SeniorDiscounts from './componants/SeniorDiscounts';

// Lazy loading components
const Contact = React.lazy(() => import('./componants/Contact'));
const Listings = React.lazy(() => import('./componants/Listings'));
// const ServicesDream = React.lazy(() => import('./componants/ServicesDream'));
// const ServicesLikelihood = React.lazy(() => import('./componants/ServicesLikelihood'));
// const ServicesEffortTime = React.lazy(() => import('./componants/ServicesEffortTime'));
// const BlogMenu = React.lazy(() => import('./componants/BlogMenu'));
// const BlogHighPerformanceWebsite = React.lazy(() => import('./componants/BlogHighPerformanceWebsite'));
// const BlogSmallBusinessWebsiteNZ = React.lazy(() => import('./componants/BlogSmallBusinessWebsiteNZ'));
// const BlogMahiDesign = React.lazy(() => import('./componants/BlogMahiDesign'));

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route 
              path="/" 
              element={
                <>
                  <Hero />
                  <MeetCarleen />
                  <CTA />
                  <MeetTheTeam />
                  <SeniorDiscounts />
                  <Reviews />
                </>
              } 
            />
            <Route path="/contact" element={<Contact />} /> 
            <Route path="/appraisal" element={<Appraisal />} /> 
            <Route path="/listings" element={<Listings />} /> 
            {/* <Route path="/blog-small-business-website-nz" element={<BlogSmallBusinessWebsiteNZ />} /> 
            <Route path="/blog-mahidesign" element={<BlogMahiDesign />} /> 
            <Route path="/services" element={
              <>
                <Services />
                <ServicesDream />
                <ServicesLikelihood />
                <ServicesEffortTime />
              </>
              } />  */}
          </Routes>
        </Suspense>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
