import React from 'react';
import './Footer.css'

const Footer = () => {
    return (
        <footer id="cs-footer-269">
            <div className="cs-container">
                {/* Logo Group */}
                <div className="cs-logo-group">
                    {/* <a aria-label="go back to home" className="cs-logo" href="#">
                        <img
                            className="cs-logo-img light"
                            aria-hidden="true"
                            loading="lazy"
                            decoding="async"
                            src="https://csimg.nyc3.digitaloceanspaces.com/Footer/logo-light.svg"
                            alt="logo"
                            width="240"
                            height="32"
                        />
                    </a> */}
                <div className="cs-logo tiny-logo-footer">
                    carleenstill.co.nz
                    </div>
                        <p className="cs-text">
                        If you are looking for someone who is a people person and who will give honest communication and feedback, Carleen is it.
                        </p>
                        <div  className="cs-logo tiny-logo-footer">
                        <img id='logo-footer'
                            src="./inspire-logo-white.png"
                        />
                    </div>
                </div>

                {/* Links */}
                <ul className="cs-nav">
                    <li className="cs-nav-li">
                        <span className="cs-header">Sitemap</span>
                    </li>
                    <li className="cs-nav-li">
                        <a className="cs-nav-link" href="/">Home</a>
                    </li>
                    <li className="cs-nav-li">
                        <a className="cs-nav-link" href="/appraisal">Appraisal</a>
                    </li>
                    <li className="cs-nav-li">
                        <a className="cs-nav-link" href="/listings">Listings</a>
                    </li>
                    <li className="cs-nav-li">
                        <a className="cs-nav-link" href="/contact">Contact</a>
                    </li>

                </ul>

                {/* Contact Info */}
                <ul className="cs-contact">
                    <li className="cs-nav-li">
                        <span className="cs-header">Details</span>
                    </li>
                    <li className="cs-contact-li">
                        <a className="cs-contact-link" href="tel:0211489804">021 148 9804</a>
                    </li>
                    <li className="cs-contact-li">
                        <a className="cs-contact-link" href="mailto:carleen@inspireproperty.co.nz">carleen@inspireproperty.co.nz</a>
                    </li>

                    {/* Social Media */}
                    <li className="cs-contact-li">
                        <div className="cs-social">
                            {/* <a className="cs-social-link" aria-label="visit google profile" href="#">
                                <img
                                    className="cs-social-img"
                                    aria-hidden="true"
                                    loading="lazy"
                                    decoding="async"
                                    src="https://csimg.nyc3.digitaloceanspaces.com/Social/google.svg"
                                    alt="google"
                                    width="11"
                                    height="11"
                                />
                            </a> */}
                            <a className="cs-social-link" aria-label="visit facebook profile" href="https://www.facebook.com/CarleenStillRealEstate/?_rdr">
                                <img
                                    className="cs-social-img"
                                    aria-hidden="true"
                                    loading="lazy"
                                    decoding="async"
                                    src="https://csimg.nyc3.digitaloceanspaces.com/Social/Facebook.svg"
                                    alt="facebook"
                                    width="6"
                                    height="11"
                                />
                            </a>
                            <a className="cs-social-link" aria-label="visit instagram profile" href="https://www.instagram.com/carleen_still/">
                                <img
                                    className="cs-social-img"
                                    aria-hidden="true"
                                    loading="lazy"
                                    decoding="async"
                                    src="https://csimg.nyc3.digitaloceanspaces.com/Social/instagram.svg"
                                    alt="instagram"
                                    width="11"
                                    height="11"
                                />
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;
