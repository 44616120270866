import React from 'react';
import './CTA.css';

const CTASection = () => {
    return (
        <section id="cta-697">
            <div className="cs-container">
                <div className="cs-content">
                    <h2 className="cs-title">Have Doubts? Get Your Queries Solved by Carleen</h2>
                    <a href="/contact" className="cs-button-solid">Get In Touch</a>
                </div>
            </div>
            {/* SVG Waves Background */}
            <img
                className="cs-background"
                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images%2FGraphics%2Fwaves-3.svg"
                alt="wave graphic"
                width="1920"
                height="318"
                loading="lazy"
                decoding="async"
                aria-hidden="true"
            />
        </section>
    );
};

export default CTASection;
