import React from 'react';
import './SeniorDiscounts.css';

const CTASection = () => {
    return (
        <section id="cta-697-1">
            <div className="cs-container">
                <div className="cs-content">
                    <h2 className="cs-title">A Golden Offer for SuperGold Cardholders</h2>
                    <p className="cs-10-percent-discount">Claim your 10% discount now, or contact us via phone or email for an appraisal.</p>
                </div>
                <a href="/contact" className="cs-button-solid">Get In Touch</a>
            </div>
            {/* SVG Waves Background */}
            <img
                className="cs-background"
                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images%2FGraphics%2Fwaves-3.svg"
                alt="wave graphic"
                width="1920"
                height="318"
                loading="lazy"
                decoding="async"
                aria-hidden="true"
            />
        </section>
    );
};

export default CTASection;
