import React from 'react';
import './Reviews.css'

const ReviewsSection = () => {
    return (
        <section id="reviews-354">
            <div className="cs-container">
                <div className="cs-content">
                    <span className="cs-topper">Testimonials</span>
                    <h2 className="cs-title">Hear What Carleen's Clients Have To Say About Her</h2>
                    <p className="cs-text">
                    With Carleen, experience a seamless real estate journey. She’s here to help you navigate every step, so you can focus on finding your perfect home.                    </p>
                </div>
                <ul className="cs-card-group">
                    <li className="cs-item">
                        {/* <img
                            className="cs-quote"
                            aria-hidden="true"
                            src="https://csimg.nyc3.digitaloceanspaces.com/Reviews/quote-blue.svg"
                            decoding="async"
                            alt="quote icon"
                            width="40"
                            height="33"
                        /> */}
                        <span className="cs-name">Helpful and Transparent</span>
                        <p className="cs-review">
                            Carleen was great to work with on the purchase of our property. She was very helpful and informative. Any questions we had she was more than happy to answer. Top notch communication! Thanks for helping make the stressfull experience a positive one.
                        </p>
                        <span className="cs-name">Mellie & Michael </span>
                        <span className="cs-job">Buyer</span>
                    </li>
                    <li className="cs-item">
                        {/* <img
                            className="cs-quote"
                            aria-hidden="true"
                            src="https://csimg.nyc3.digitaloceanspaces.com/Reviews/quote-blue.svg"
                            decoding="async"
                            alt="quote icon"
                            width="40"
                            height="33"
                        /> */}
                        <span className="cs-name">Carleen is Fabulous!!</span>

                        <p className="cs-review">
                            We had a situation where we had signed up with the agent we had brought the new house from, it was a nightmare, we finally got free of them and got Carleen, so as you can imagine we were pretty stressed. Carleen got us a cash offer after the first open home for the price...
                        </p>
                        <span className="cs-name">Donna Burchett</span>
                        <span className="cs-job">Seller</span>
                    </li>
                    <li className="cs-item">
                        {/* <img
                            className="cs-quote"
                            aria-hidden="true"
                            src="https://csimg.nyc3.digitaloceanspaces.com/Reviews/quote-blue.svg"
                            decoding="async"
                            alt="quote icon"
                            width="40"
                            height="33"
                        /> */}
                        <span className="cs-name">Carleen goes the extra mile</span>
                        <p className="cs-review">
                            Right from the beginning when we decided to buy our new home from Carleen she made sure we had all the documentation we needed to get bank approval, this even included contacting Whangarei Council on our behalf. She negotiated fairly between us and the Vendor and acheived...
                        </p>
                        <span className="cs-name">Pauline & Thomas</span>
                        <span className="cs-job">Buyer</span>
                    </li>
                </ul>
            </div>
        </section>
    );
};

export default ReviewsSection;
