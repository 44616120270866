import React from 'react';
import './Hero.css'

const HeroSection = () => {
    return (
        <section id="hero-1618">
            <div className="cs-container">
                <div className="cs-content">
                    <span className="cs-topper">
                        <svg className="cs-chevron" width="49" height="15" viewBox="0 0 49 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.7">
                                {/* Repeat the chevron paths here */}
                                {Array.from({ length: 6 }).map((_, index) => (
                                    <path
                                        key={index}
                                        d="M0.621948 7.49889L6.40262 15L10.343 15L4.56231 7.49889L10.343 0L6.40262 0L0.621948 7.49889Z"
                                        fill="var(--chevronColor)"
                                        transform={`translate(${index * 7}, 0)`}
                                    />
                                ))}
                            </g>
                        </svg>
                        carleen still
                        <svg className="cs-chevron" width="49" height="15" viewBox="0 0 49 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.7">
                                {Array.from({ length: 6 }).map((_, index) => (
                                    <path
                                        key={index}
                                        d="M48.3781 7.49889L42.5974 15L38.657 15L44.4377 7.49889L38.657 0L42.5974 0L48.3781 7.49889Z"
                                        fill="var(--chevronColor)"
                                        transform={`translate(-${index * 7}, 0)`}
                                    />
                                ))}
                            </g>
                        </svg>
                    </span>
                    {/* <h1 className="cs-title">North Island Lifestyle Block Sales + <br></br>Country Living</h1> */}
                    {/* <h1 className="cs-title">Real Estate for North Island Lifestyle + <br></br>Acreage</h1> */}
                    {/* <h1 className="cs-title">Lifestyle Block Sales + <br></br>North Island Property Expert</h1> */}
                    <h1 className="cs-title">Looking for someone <br></br>who cares?<br></br>Give me a call!</h1>
                    <a href="/contact" className="cs-button-solid">Get In Touch!</a>
                </div>
                <ul className="cs-card-group">
                    <li className="cs-item">
                        <img
                            className="cs-icon"
                            loading="lazy"
                            decoding="async"
                            src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Icons/check-circle.svg"
                            alt="icon"
                            width="48"
                            height="48"
                        />
                        <h2 className="cs-h3">Free Appraisal</h2>
                        <p className="cs-item-text">
                            Curious about market value? Enjoy a free, expert appraisal with zero obligations.
                        </p>
                    </li>
                    <li className="cs-item">
                        <img
                            className="cs-icon"
                            loading="lazy"
                            decoding="async"
                            src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Icons/speedometer.svg"
                            alt="icon"
                            width="48"
                            height="48"
                        />
                        <h2 className="cs-h3">Client Focus</h2>
                        <p className="cs-item-text">
                            Our commitment to you is more than a transaction – it’s building a lasting partnership.                        </p>
                    </li>
                    <li className="cs-item">
                        <img
                            className="cs-icon"
                            loading="lazy"
                            decoding="async"
                            src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Icons/Like.svg"
                            alt="icon"
                            width="48"
                            height="48"
                        />
                        <h2 className="cs-h3">Area Knowledge</h2>
                        <p className="cs-item-text">
                            Detailed knowledge of the North Island area, including hidden gems and growing areas.                        </p>
                    </li>
                </ul>
            </div>
            <picture className="cs-background">
                <source media="(max-width: 600px)" srcSet="./town-basin.JPG" />
                <source media="(min-width: 601px)" srcSet="./town-basin.JPG" />
                <img
                    decoding="async"
                    src="./town-basin.JPG"
                    alt="meeting"
                    width="2250"
                    height="1500"
                    aria-hidden="true"
                />
            </picture>
        </section>
    );
};

export default HeroSection;
