import React from 'react';
import './MeetCarleen.css';

const SideBySideReverseAlt = () => {
    return (
        <section id="sbsr-298">
            <div className="cs-container">
                {/* Left Image Section */}
                <div className="cs-image-group">
                    {/* Floating Box */}
                    {/* <div className="cs-box">
                        <span className="cs-number">14</span>
                        <span className="cs-desc">years of practicing</span>
                        <picture className="cs-box-picture">
                            <source
                                media="(max-width: 600px)"
                                srcSet="https://csimg.nyc3.digitaloceanspaces.com/SideBySide/justice-m.jpg"
                            />
                            <source
                                media="(min-width: 601px)"
                                srcSet="https://csimg.nyc3.digitaloceanspaces.com/SideBySide/justice.jpg"
                            />
                            <img
                                loading="lazy"
                                decoding="async"
                                src="https://csimg.nyc3.digitaloceanspaces.com/SideBySide/justice.jpg"
                                alt="greek pillars"
                                width="521"
                                height="672"
                                aria-hidden="true"
                            />
                        </picture>
                    </div> */}
                    {/* Big Background Image */}
                    <picture className="cs-picture">
                        <source
                            media="(max-width: 600px)"
                            srcSet="./meetcarleen.jpg"
                        />
                        <source
                            media="(min-width: 601px)"
                            srcSet="./meetcarleen.jpg"
                        />
                        <img
                            loading="lazy"
                            decoding="async"
                            src="https://csimg.nyc3.digitaloceanspaces.com/SideBySide/pillars.jpg"
                            alt="greek pillars"
                            width="521"
                            height="672"
                            aria-hidden="true"
                        />
                    </picture>
                </div>
                {/* Right Content Section */}
                <div className="cs-content">
                    <span className="cs-topper">Meet Carleen</span>
                    <h2 className="cs-title">What Makes Carleen Unique?</h2>
                    <p className="cs-text">
                        Carleen started her Real Estate career in Waipu and added to her Real Estate License by becoming a Branch Manager and an auctioneer. Carleen has owned her own Beauty Business, has excellent marketing and communication skills, and has a Diploma in Management which reflects in her professional approach to Real Estate.
                    </p>
                    <p className="cs-text">
                    Carleen has many strings to her bow, she is an International Country Music Recording Artist and has 5 successful CDs, she is heavily involved in numerous festivals and fundraisers up and down New Zealand. Carleen has 3 nominations for Kiwi ProAm Country Music Female Artist of the Year and is deeply passionate about fundraising and uses her talents to achieve outstanding results for her chosen charities.
                    </p>
                    <p className="cs-text">
                    Carleen has been associated with the Bream Bay area for several years, having lived in One Tree Point and her 2 children attended the local Ruakaka Primary School.
                    </p>
                    <p className="cs-text">
                    Carleen and her husband live on a 20 acre block property in Oakleigh and have a strong connection to Northland and the farming community.
                    </p>
                    <p className="cs-text">
                    Carleen is extremely passionate about EVERYTHING she does, and she treats people the way she would like to be treated. Carleen ALWAYS gives 100% commitment. Empowered with the support, resources, and deep market data set that comes with Market-leading Brand Power
                    </p>
                    <p className="cs-text">
                    Carleen has the capacity and backing to ensure her customer experience is second to none. Results and customer care at the forefront of her business means her clients will be looked after - before, during and beyond the buying and selling process.
                    </p>
                    <p className="cs-text">
                    So, if you are looking for someone who is a people person and who will give honest communication and feedback, Carleen is it.
                    </p>
                    <p className="cs-text">
                        Call to ask any question: <a className="cs-link" href="tel:0211489804">021 148 9804</a>
                    </p>
                    <p className="cs-text">
                        <b>Inspire Real Estate Limited (Licensed REA 2008)</b>
                    </p>
                    <a className="cs-button-solid" aria-label="learn more about our programs" href="/contact">
                        Get In Touch
                    </a>
                </div>
            </div>
        </section>
    );
};

export default SideBySideReverseAlt;
